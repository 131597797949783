<template>
<div class="ny_pad">
	<div class="menu_ico">
	 <top_menu></top_menu>
	 <!-- ho_menu -->
	 </div>
	 <!-- about_lay zp -->
	 <div class="">
	    <div class="about_wapper" id="rc1">
        <img src="@/assets/images/zp.jpg" />
<!--	     <dl class="fontlay dj_lay wid_100b gonggao_tab">-->
<!--	       <p class="wid_100b text_cen"><img src="@/assets/images/icon33.png" /></p>-->
<!--	      <ul id="myTab1" class="riqi_tab">-->
<!--	       <li class="active" href="#taocan1" data-toggle="tab">进行中</li>-->
<!--	       <li href="#taocan2" data-toggle="tab">未开始</li>-->
<!--	       <li href="#taocan3" data-toggle="tab">面试公告</li>-->
<!--	      </ul>-->
<!--	      <dd id="myTabContent1">-->
<!--	       <div class="tab-pane fade active in" id="taocan1">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata1" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	       <div class="tab-pane fade" id="taocan2">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata2" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	       <div class="tab-pane fade" id="taocan3">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata3" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	      </dd>-->
<!--	     </dl>-->
	    </div>
	    <!-- about_wapper -->
	  </div>
	  <!-- about_lay zhaopin_bg2-->

  <div class="container">
    <p class="wid_100b text_cen"><img src="@/assets/images/icon33.png" /></p>
    <div class="row blog-left-content" v-for="(item, index) in testdata1" :key="index">

      <div class="content col-lg-8 col-md-12 col-sm-12">
        <H3><a :href="'/content?id='+item.id">{{(item.title||'')}}</a></H3>
        <!--              <p>{{(item.description||'')}}</p>-->
        <!--              <a :href="'/content?'+item.id" class="read-btn">Read More</a>-->
      </div>

      <div class="blog-right col-lg-2 col-md-12 col-sm-12">
        {{(item.publishTime||'').slice(0,10).replace(/-/g,'/')}}
      </div>


    </div>
    <div>
      <a :href="'/list?catid=81'"><img src="@/assets/images/m.jpg" /></a>
    </div>
  </div>

	  <div class="about_lay ">
	    <div class="about_wapper" id="rc2">
        <div class="container">
          <p class="wid_100b text_cen"><img src="@/assets/images/icon35.png" /></p>
          <div class="row blog-left-content" v-for="(item, index) in testdata2" :key="index">

            <div class="content col-lg-8 col-md-12 col-sm-12">
              <H3><a :href="'/content?id='+item.id">{{(item.title||'')}}</a></H3>
<!--              <p>{{(item.description||'')}}</p>-->
<!--              <a :href="'/content?'+item.id" class="read-btn">Read More</a>-->
            </div>

            <div class="blog-right col-lg-2 col-md-12 col-sm-12">
              {{(item.publishTime||'').slice(0,10).replace(/-/g,'/')}}
            </div>


          </div>
          <div>
            <a :href="'/list?catid=82'"><img src="@/assets/images/m.jpg" /></a>
          </div>
        </div>
<!--	     <dl class="fontlay dj_lay wid_100b gonggao_tab">-->
<!--	       <p class="wid_100b text_cen"><img src="@/assets/images/icon35.png" /></p>-->
<!--	      <ul id="myTab1" class="riqi_tab">-->
<!--	       <li class="active" href="#taocanq1" data-toggle="tab">进行中</li>-->
<!--	       <li href="#taocanq2" data-toggle="tab">未开始</li>-->
<!--	       <li href="#taocanq3" data-toggle="tab">面试公告</li>-->
<!--	      </ul>-->
<!--	      <dd id="myTabContent2">-->
<!--	       <div class="tab-pane fade active in" id="taocanq1">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata4" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	       <div class="tab-pane fade" id="taocanq2">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata5" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	       <div class="tab-pane fade" id="taocanq3">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata6" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	      </dd>-->
<!--	     </dl>-->
	     <!-- dj_lay -->
	    </div>
	    <!-- about_wapper -->
	  </div>

	  <!-- about_lay zhaopin_bg3-->
	  <div class="about_lay ">
	    <div class="about_wapper" id="rc3">
<!--        <div class="container">-->
<!--          <p class="wid_100b text_cen"><img src="@/assets/images/icon37.png" /></p>-->
<!--          <div class="row blog-left-content" v-for="(item, index) in testdata3" :key="index">-->

<!--            <div class="content col-lg-8 col-md-12 col-sm-12">-->
<!--              <H3><a :href="'/content?id='+item.id">{{(item.title||'')}}</a></H3>-->
<!--              <p>{{(item.description||'')}}</p>-->
<!--&lt;!&ndash;              <a :href="'/content?'+item.id" class="read-btn">Read More</a>&ndash;&gt;-->
<!--            </div>-->

<!--            <div class="blog-right col-lg-2 col-md-12 col-sm-12">-->
<!--              {{(item.publishTime||'').slice(0,10).replace(/-/g,'/')}}-->
<!--            </div>-->


<!--          </div>-->
<!--        </div>-->



<!--	     <dl class="fontlay dj_lay wid_100b gonggao_tab">-->
<!--	       <p class="wid_100b text_cen"><img src="@/assets/images/icon37.png" /></p>-->
<!--	      <ul id="myTab1" class="riqi_tab">-->
<!--	       <li class="active" href="#taocanw1" data-toggle="tab">进行中</li>-->
<!--	       <li href="#taocanw2" data-toggle="tab">未开始</li>-->
<!--	       <li href="#taocanw3" data-toggle="tab">面试公告</li>-->
<!--	      </ul>-->
<!--	      <dd id="myTabContent3">-->
<!--	       <div class="tab-pane fade active in" id="taocanw1">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata7" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	       <div class="tab-pane fade" id="taocanw2">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata8" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	       <div class="tab-pane fade" id="taocanw3">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata9" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	      </dd>-->
<!--	     </dl>-->
	     <!-- dj_lay -->
	    </div>
	    <!-- about_wapper -->
	  </div>
	  <!-- about_lay -->
	 <fo_oter></fo_oter>
	<!-- footer -->
</div>
</template>

<script>
import top_menu from '../components/menu.vue'
import fo_oter from '../components/footer.vue'
import "@/assets/js/jquery-1.9.1.min.js";
import "@/assets/bootstrap/bootstrap.min.js";
import {GETDATA1,GETDATA2,GETDATA3,GETDATA4,GETDATA5,GETDATA6,GETDATA7,GETDATA8,GETDATA9} from '../apis/zhaopin.js'
export default {
  name: 'about',
  components: {
    top_menu,fo_oter
  },
  data() {
    return {
		testdata1:[],
		testdata2:[],
		testdata3:[],
		testdata4:[],
		testdata5:[],
		testdata6:[],
		testdata7:[],
		testdata8:[],
		testdata9:[]
    };
  },
  mounted() {
  	this.onloading();
  	  // if (location.href.indexOf("#reloaded") == -1) {
  	  //    location.href = location.href + "#reloaded";
  	  //    location.reload();
  	  // };
  },
  methods: {
  	async onloading(){
  	   this.testdata1 = await GETDATA1();
  	   this.testdata2 = await GETDATA2();
  	   this.testdata3 = await GETDATA3();
  	 // this.testdata4 = await GETDATA4();
	   // this.testdata5 = await GETDATA5();
	   // this.testdata6 = await GETDATA6();
	   // this.testdata7 = await GETDATA7();
	   // this.testdata8 = await GETDATA8();
	   // this.testdata9 = await GETDATA9();
  	   this.testdata1 = this.testdata1.rows;
  	   this.testdata2 = this.testdata2.rows;
  	   this.testdata3 = this.testdata3.rows;
	   // this.testdata4 = this.testdata4.rows;
	   // this.testdata5 = this.testdata5.rows;
	   // this.testdata6 = this.testdata6.rows;
	   // this.testdata7 = this.testdata7.rows;
	   // this.testdata8 = this.testdata8.rows;
	   // this.testdata9 = this.testdata9.rows;
  	}
  }
}
</script>